@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #2f316a;
  outline: none;
}

.dashboard-table-container {
  margin-bottom: 56px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px #bbb;
  box-shadow: 0 0 5px #bbb;
}

.card-layout {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  position: relative;
}

.card-layout .status-label {
  position: absolute;
  right: 15px;
  font-size: 14px;
  top: 12px;
}

.panel-primary {
  border-color: #ed9a37;
}

.text-center .row-text {
  border: 1px solid #ccc;
  margin: 12px 0;
  text-align: right;
  font-size: 14px !important;
}

.text-center {
  margin: 0 auto 24px auto;
  max-width: 30em;
}

.wpwl-brand-card {
  float: unset;
}

.wpwl-group-brand .wpwl-wrapper-brand {
  padding-right: 0;
}

.wpwl-container-card .wpwl-form-card {
  background-color: #4e859a;
  box-shadow: none;
}

div.wpwl-wrapper,
div.wpwl-label,
div.wpwl-sup-wrapper {
  width: 100%;
}

div.wpwl-label,
div.wpwl-sup-wrapper {
  color: #fff;
}

.text-center .row-text .col-md-5 {
  font-weight: 900;
}

.wpwl-container-card .wpwl-label-brand {
  padding-bottom: 15px;
}

.wpwl-group-submit .wpwl-button-pay {
  background-color: #ffd600;
  border-color: #ffd600;
  border-radius: 50px;
  color: #000;
  padding: 10px 25px;
}

.wpwl-group .wpwl-control {
  padding: 6px 10px;
  height: 2.7em;
}

.dashboard-table-container .card-layout {
  margin: 0 auto 24px auto;
  max-width: 60em;
}

.payment-container {
  min-width: 60vw;
}

.panel-primary {
  border-color: #ed9a37;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #ed9a37;
  border-color: #ed9a37;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ed9a37;
}

.panel-primary>.panel-heading .badge {
  color: #ed9a37;
  background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ed9a37;
}

.Toastify__toast-body {
  font-family: "Montserrat", sans-serif !important;
}

.sidebar-transition {
  transition: all 0.3s ease-in-out;
}

.side-bar-menu-button>aside>span {
  top: 10px !important;
}

.list-header-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 12px 0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
  border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.list-header-container>div {
  display: flex;
  flex: none !important;
  width: 200px !important;
}

.list-header-container>.md-col {
  display: flex;
  flex: none !important;
  width: 120px !important;
}

.list-header-container>.small-col {
  display: flex;
  flex: none !important;
  width: 80px !important;
}

.list-header-container>div>div {
  display: flex;
  flex: none !important;
  width: 100% !important;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-lightblue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(227 245 255 / var(--tw-border-opacity));
}

.border-lightblue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(177 227 255 / var(--tw-border-opacity));
}

.border-lightgreen-100 {
  --tw-border-opacity: 1;
  border-color: rgb(186 237 189 / var(--tw-border-opacity));
}

.border-lightgreen-100\/80 {
  border-color: #baedbdcc;
}

.border-lightpurple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(198 199 248 / var(--tw-border-opacity));
}

.border-lightred {
  --tw-border-opacity: 1;
  border-color: rgb(255 71 71 / var(--tw-border-opacity));
}

.border-lightred\/80 {
  border-color: #ff4747cc;
}

.border-lightyellow {
  --tw-border-opacity: 1;
  border-color: rgb(255 233 153 / var(--tw-border-opacity));
}

.border-lightyellow\/80 {
  border-color: #ffe999cc;
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-b-green-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-l-indigo-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-lightred {
  --tw-border-opacity: 1;
  border-right-color: rgb(255 71 71 / var(--tw-border-opacity));
}

.border-r-yellow-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-t-lightred {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 71 71 / var(--tw-border-opacity));
}

.\!bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity)) !important;
}

.\!bg-lightred {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 71 71 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#000\]\/5 {
  background-color: #0000000d;
}

.bg-\[\#8A8CD9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(138 140 217 / var(--tw-bg-opacity));
}

.bg-\[\#DEF8EE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 248 238 / var(--tw-bg-opacity));
}

.bg-\[\#E2F5FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(226 245 255 / var(--tw-bg-opacity));
}

.bg-\[\#EDEDFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 255 / var(--tw-bg-opacity));
}

.bg-\[\#FFFBD4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 212 / var(--tw-bg-opacity));
}

.bg-\[black\]\/60 {
  background-color: #0009;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: #1c1c1c1a;
}

.bg-black\/20 {
  background-color: #1c1c1c33;
}

.bg-black\/30 {
  background-color: #1c1c1c4d;
}

.bg-black\/40 {
  background-color: #1c1c1c66;
}

.bg-black\/5 {
  background-color: #1c1c1c0d;
}

.bg-black\/50 {
  background-color: #1c1c1c80;
}

.bg-black\/60 {
  background-color: #1c1c1c99;
}

.bg-black\/80 {
  background-color: #1c1c1ccc;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-300\/50 {
  background-color: #a5b4fc80;
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-lightblue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 245 255 / var(--tw-bg-opacity));
}

.bg-lightblue-100\/50 {
  background-color: #e3f5ff80;
}

.bg-lightblue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(177 227 255 / var(--tw-bg-opacity));
}

.bg-lightblue-200\/50 {
  background-color: #b1e3ff80;
}

.bg-lightblue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 197 218 / var(--tw-bg-opacity));
}

.bg-lightblue-300\/20 {
  background-color: #a8c5da33;
}

.bg-lightgreen-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 237 189 / var(--tw-bg-opacity));
}

.bg-lightgreen-100\/20 {
  background-color: #baedbd33;
}

.bg-lightgreen-100\/50 {
  background-color: #baedbd80;
}

.bg-lightgreen-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 227 203 / var(--tw-bg-opacity));
}

.bg-lightpurple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 236 246 / var(--tw-bg-opacity));
}

.bg-lightpurple-100\/50 {
  background-color: #e5ecf680;
}

.bg-lightpurple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(198 199 248 / var(--tw-bg-opacity));
}

.bg-lightpurple-200\/50 {
  background-color: #c6c7f880;
}

.bg-lightpurple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(149 164 252 / var(--tw-bg-opacity));
}

.bg-lightred {
  --tw-bg-opacity: 1;
  background-color: rgb(255 71 71 / var(--tw-bg-opacity));
}

.bg-lightred\/20 {
  background-color: #ff474733;
}

.bg-lightred\/50 {
  background-color: #ff474780;
}

.bg-lightwhite {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 251 / var(--tw-bg-opacity));
}

.bg-lightyellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 233 153 / var(--tw-bg-opacity));
}

.bg-lightyellow\/20 {
  background-color: #ffe99933;
}

.bg-lightyellow\/50 {
  background-color: #ffe99980;
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.cursor-pointer {
  cursor: pointer;
}

.custom-dropdown {
  align-items: center;
}

.collection-portal.logo {
  width: 150px;
}

.spacing {
  margin: 30px 0px 30px 0px;
}

.forgot-pass span {
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
  color: #4e859a;

}

.welcome-container {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 12rem;
}

.welcome-container h1 {
  font-size: 32px;
  width: max-content;
  font-weight: 600;
  color: white;
  border-color: #1677ff;
  background-color: rgb(47, 49, 106);
  padding: 15px 30px;
  border-radius: 10px;
}